import React from 'react'

import Markdown from 'markdown-to-jsx';

import Layout from "../components/layout";

import {graphql, HeadFC} from "gatsby";
// for rendering the blocks to html
import CategoryNav, {CategoryItem} from "../components/category-nav";

import {Category} from "../@types/category";

import Consumer from "../context"
import Testimonial from "../components/testimonial";
import {SEO} from "../components/seo";

import '../styles/post.css'

interface Props {
    data: {
        strapiArticle: {
            id: string
            slug: string
            title: string
            description: string
            content: {
                data: {
                    childMarkdownRemark: {
                        html: string
                    }
                    content: string
                }
            }
            category: {
                name: Category
                name_en: string
                slug: string
                bg_color: {
                    code:string
                }
                bg_image: {
                    url: string
                }
                articles: {
                    title: string
                    strapi_id: number
                    slug: string
                } []
            }
            testimonials: {
                name: string
                title: string
                quote: string
                avatar: {
                    url: string
                }
            } []
        }
    }
}

// the template needs all posts in a category for the secondary nav
// then the article with its slug
export default function PostDetails( {data}: Props ) {

    const art = data.strapiArticle;
    const category = art.category;

    let page_title = '';

    // generate the category menu
    let art_cats = category.articles.sort( (a, b) => (a.strapi_id > b.strapi_id) ? 1 : -1 ).
    map( (item) => {
        let ret: CategoryItem = {
            title: item.title,
            href: '/' + category.slug + "/" + item.slug,
            active: item.slug === art.slug
        }

        if (ret.active) {
            page_title = ret.title
        }

        return ret;
    })

    return (
        <Layout>
            <SEO title={page_title} description={'爱尔兰高威大学 - ' + page_title}></SEO>
            <Consumer>
                {({ data, set }) => (
                    (data.category === category.slug) ? console.log(data.category) :  set({ category: category.slug })
                )}
            </Consumer>

            {/*the second menu*/}
            <div className="mt-10">
                <CategoryNav cats={art_cats} />
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
                <div className="px-4 py-5 flex-auto text-galway-maroon">
                    <article className="
                            prose prose-slate
                            hover:prose-a:text-magenta
                            prose-h2:text-galway-maroon
                            prose-th:bg-galway-maroon
                            prose-th:text-white
                            prose-th:pl-2 prose-th:pt-1
                            prose-td:pl-2

                            prose-th:border-x-2
                            prose-th:border-white
                            prose-tr:border-0
                            prose-td:border-x-2
                            prose-td:border-white

                            prose-img:border-l-[24px]
                            prose-img:border-l-galway-maroon
                            mx-auto max-w-7xl mt-8"
                    >
                        <Markdown>{art.content.data.content}</Markdown>
                    </article>
                    <section className="mx-auto max-w-7xl flex flex-wrap -m-4 mb-20">
                        {
                            art.testimonials.map( ( item ) => {
                                return (
                                    <>
                                        <Testimonial name={item.name} title={item.title} photo={item.avatar.url} >
                                            {item.quote}
                                        </Testimonial>
                                    </>
                                )
                            } )
                        }
                    </section>
                </div>
            </div>
        </Layout>
    )
}


export const query = graphql`
    query Articles($art_id: String) {
      strapiArticle(id: {eq: $art_id}) {
        id
        slug
        title
        description
        content {
          data {
            childMarkdownRemark {
              html
            }
            content
          }
        }
        category {
          name
          name_en
          slug
          bg_color {
            code
          }
          bg_image {
            url
          }
          articles {
            slug
            strapi_id
            title
          }
        }
        testimonials {
          name
          title
          quote
          avatar {
            url
          }
        }
      }
    }
`
